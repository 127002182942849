import logo from './logo.png';
import logobleu from './blue-logo.png';

import Rectangle from './Rectangle.png';
import Rectangle1 from './Rectangle-1.png';
import Rectangle2 from './Rectangle-2.png';
import Rectangle3 from './Rectangle-3.png';
import Rectangle4 from './Rectangle-4.png';
import Rectangle5 from './Rectangle-5.png';

import Rectanglexz from './Rectanglexz.png';
import Oval from './oval.png';

import footer_bottom from './footer_bottom.png';
import footer_playstore from './footer_playstore.png';
import footer_appstore from './footer_appstore.png';
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import Notifications from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";

import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import IconButton from '@material-ui/core/IconButton';


import React, { useState, useEffect } from "react";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

import './App.css';
import './css/main.css';
// import Button from 'react-bootstrap/Button';
import { httpsCallable } from 'firebase/functions';
import { storage, functions } from "./firebase"; // add
import Grid from '@material-ui/core/Grid';
const dangerColor = ["#f44336", "#ef5350", "#e53935", "#f55a4e"];
const whiteColor = "#FFF";
const styles = {
  notifications: {
    zIndex: "4",
    position: "absolute",
    top: "2px",
    border: "1px solid " + whiteColor,
    right: "4px",
    fontSize: "9px",
    background: dangerColor[0],
    color: whiteColor,
    minWidth: "16px",
    height: "16px",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "16px",
    verticalAlign: "middle",
    display: "block",
  }
}


const useStyles = makeStyles(styles);

function App() {

  const [item, setItem] = useState("")
  const [itemID, setItemID] = useState("")

  const [cart, setCart] = useState([]);

  const addTeeShirtToCart = () => {
    setItem("Tee-Shirt")
    setItemID("1")
    setSelectedPrice(19.99)
    setSize(true)
    // let newElement = { id: '1', name: 'Tee-Shirt' }
    // setCart([...cart, newElement]);
  };

  const addPullToCart = () => {
    setItem("Pull")
    setItemID("2")
    setSelectedPrice(39.99)
    setSize(true)
    // let newElement = { id: '2', name: 'Pull' }
    // setCart([...cart, newElement]);
  };

  const addSacToCart = () => {
    let newElement = { id: '3', name: 'Sac Isotherme', price: 29.99 }
    setCart([...cart, newElement]);
  };

  const addCasqueToCart = () => {
    let newElement = { id: '4', name: 'Casque de Protection', price: 9.99 }
    setCart([...cart, newElement]);
  };

  const addEclairageToCart = () => {
    let newElement = { id: '5', name: 'Éclairage avant LED', price: 4.99 }
    setCart([...cart, newElement]);
  };

  const addBatterieToCart = () => {
    let newElement = { id: '6', name: 'Batterie Autonome', price: 79.99 }
    setCart([...cart, newElement]);
  };

  const [open, setOpen] = React.useState(false);
  const [openAdr, setOpenAdr] = React.useState(false);
  const [adresse, setAdresse] = React.useState(false);
  const changeAdresse = (event) => {
    setAdresse(event.target.value)
  };
  const [nom, setNom] = React.useState(false);
  const changeNom = (event) => {
    setNom(event.target.value)
  };
  const [prenom, setPrenom] = React.useState(false);
  const changePrenom = (event) => {
    setPrenom(event.target.value)
  };
  const [info, setInfo] = React.useState(false);
  const changeInfo = (event) => {
    setInfo(event.target.value)
  };

  const [total, setTotal] = useState(0);
  const handleClickOpen = () => {
    let t = 0;
    cart.forEach((elem) => {
      t += elem.price
    })
    setTotal(t)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    console.log(cart)
  };

  const handleCloseOk = () => {
    setOpenAdr(true);
  }
  const handleCloseAdr = () => {
    setOpenAdr(false);
    console.log(cart)
  };
  function checkExists(name, items, _price) {
    let price = _price[name];
    let ret = false;
    items.forEach(item => {
      if (item.price == price) {
        ret = true;
      }
    });
    return ret;
  }


  const handleCloseAdrOk = () => {
    console.log(adresse)
    console.log(nom)
    console.log(prenom)
    console.log(info)
    console.log(cart)
    // let _ata = cart;
    // let _price = {
    //   "Tee-Shirt": "price_1KW5IwE0xQ0PeriwiWk45W0r",
    //   "Pull": "price_1KW5JFE0xQ0PeriwaemN5f7l",
    //   "Sac Isotherme": "price_1KW5JcE0xQ0PeriwUs2o7sWo",
    //   "Casque de Protection": "price_1KW5K7E0xQ0Periwhg3DXJ2y",
    //   "Éclairage avant LED": "price_1KW5KSE0xQ0PeriwjrMDfrqJ",
    //   "Batterie Autonome": "price_1KW5KxE0xQ0PeriwPXU78kun",
    // }
    // let items = [];
    // _ata.forEach(element => {
    //   console.log(element)
    //   if (!checkExists(element.name, items, _price)) {
    //     items.push({
    //       price: _price[element.name],
    //       quantity: 1,
    //     })
    //   } else {
    //     let i = items.findIndex(item => item.price == _price[element.name])
    //     items[i].quantity += 1;
    //   }

    // });
    // console.log(items)
    const checkout = httpsCallable(functions, 'createCheckout');
    checkout({ data: cart, adress: adresse, nom: nom, prenom: prenom, info: info })
      .then((result) => {
        console.log(result.data.url);
        setOpen(false);
        window.location = result.data.url
      }).catch((error) => {
        console.log(error);
      });
  };


  const [size, setSize] = React.useState(false);
  const [selectedSize, setSelectedSize] = useState("")
  const [selectedPrice, setSelectedPrice] = useState(0)
  const [s, setS] = React.useState("outlined");
  const [m, setM] = React.useState("outlined");
  const [l, setL] = React.useState("outlined");
  const [xl, setXL] = React.useState("outlined");


  const handleCloseSize = () => {
    setItem("")
    setItemID("")
    setSelectedPrice(0)
    setSize(false);
  };
  const handleCloseSizeOk = () => {
    let newElement = { id: itemID, name: item, size: selectedSize, price: selectedPrice }
    setCart([...cart, newElement]);
    setSize(false);
    setItem("")
    setSelectedPrice(0)
    setSelectedSize("")
    setItemID("")
  };


  const changeSizeS = () => {
    setSelectedSize("S")
    setS("contained")
    setM("outlined")
    setL("outlined")
    setXL("outlined")
  };
  const changeSizeM = () => {
    setSelectedSize("M")
    setS("outlined")
    setM("contained")
    setL("outlined")
    setXL("outlined")
  };
  const changeSizeL = () => {
    setSelectedSize("L")
    setS("outlined")
    setM("outlined")
    setL("contained")
    setXL("outlined")
  };
  const changeSizeXL = () => {
    setSelectedSize("XL")
    setS("outlined")
    setM("outlined")
    setL("outlined")
    setXL("contained")
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #042C5C',
    boxShadow: 24,
    borderRadius: '6px',
    p: 4,
  };
  const classes = useStyles();
  const removeItemSized = (item, size) => {
    let arr = [];
    cart.forEach((el) => {
      arr.push(el)
    })
    let i = arr.indexOf(cart.find(i => (i.id == item && i.size == size)))
    arr.splice(i, 1)
    setCart(arr)
  }
  const removeItem = (item) => {
    let arr = [];
    cart.forEach((el) => {
      arr.push(el)
    })
    let i = arr.indexOf(cart.find(i => i.id == item))
    arr.splice(i, 1)
    setCart(arr)
  }
  return (
    <div className="wrapper">
      <Dialog open={size} onClose={handleCloseSize}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez sélectionner une taille pour votre vêtement
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button variant={s} color="primary" onClick={changeSizeS}>
                S
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant={m} color="primary" onClick={changeSizeM}>
                M
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant={l} color="primary" onClick={changeSizeL}>
                L
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant={xl} color="primary" onClick={changeSizeXL}>
                XL
              </Button>
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSize}>Annuler</Button>
          <Button onClick={handleCloseSizeOk}>Accepter</Button>
        </DialogActions>
      </Dialog>




      <Dialog open={openAdr} onClose={handleCloseAdr}>
        <DialogTitle>Adresse de livraison</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Afin d'accéder au paiement, veuillez renseigner vos informations de livraison.
          </DialogContentText>
          <TextField
            onChange={changeAdresse}
            autoFocus
            margin="dense"
            id="name"
            label="Adresse"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange={changeNom}
            autoFocus
            margin="dense"
            id="name"
            label="Prénom"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange={changePrenom}
            autoFocus
            margin="dense"
            id="name"
            label="Nom"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange={changeInfo}
            autoFocus
            margin="dense"
            id="name"
            label="Informations Supplémentaires"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdr}>Annuler</Button>
          <Button onClick={handleCloseAdrOk}>Accepter</Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div class="top-cart">
            <IconButton aria-label="delete" size="large" onClick={handleClose}>
              <Close fontSize="inherit" />
            </IconButton>
          </div>
          <div class="list-cart">
            {cart.map(item => {
              switch (item.id) {
                case '1':
                  return (
                    <div className='item-cart-container'>
                      <div className='item-cart-delete'>
                        <IconButton className='test-cart col-1' aria-label="delete" size="large" onClick={() => removeItemSized(item.id, item.size)}>
                          <Delete color='error' fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div className='row align-items-center'>
                        <div className='item-cart-image col-sm'>
                          <img src={Rectangle4} alt="Tee-Shirt" />
                        </div>
                        <div className='item-cart-name col-sm-5 col-12'>
                          <h1>Tee-Shirt ({item.size})</h1>
                        </div>
                        <div className='item-cart-price col-sm-3 col-12 float-end'>
                          <h2>19.99 € </h2>
                        </div>
                      </div>
                    </div>
                  )
                case '2':
                  return (
                    <div className='item-cart-container'>
                      <div className='item-cart-delete'>
                        <IconButton className='test-cart col-1' aria-label="delete" size="large" onClick={() => removeItemSized(item.id, item.size)}>
                          <Delete color='error' fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div className='row align-items-center'>
                        <div className='item-cart-image col-sm'>
                          <img src={Rectangle3} alt="Pull" />
                        </div>
                        <div className='item-cart-name col-sm-5 col-12'>
                          <h1>Pull ({item.size})</h1>
                        </div>
                        <div className='item-cart-price col-sm-3 col-12 float-end'>
                          <h2>39.99 € </h2>
                        </div>
                      </div>
                    </div>
                  )
                case '3':
                  return (
                    <div className='item-cart-container'>
                      <div className='item-cart-delete'>
                        <IconButton className='test-cart col-1' aria-label="delete" size="large" onClick={() => removeItem(item.id)}>
                          <Delete color='error' fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div className='row align-items-center'>
                        <div className='item-cart-image col-sm'>
                          <img src={Rectangle2} alt="Sac isotherme" />
                        </div>
                        <div className='item-cart-name col-sm-5 col-12'>
                          <h1>Sac Isotherme</h1>
                        </div>
                        <div className='item-cart-price col-sm-3 col-12 float-end'>
                          <h2>29.99 € </h2>
                        </div>
                      </div>
                    </div>
                  )
                case '4':
                  return (
                    <div className='item-cart-container'>
                      <div className='item-cart-delete'>
                        <IconButton className='test-cart col-1' aria-label="delete" size="large" onClick={() => removeItem(item.id)}>
                          <Delete color='error' fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div className='row align-items-center'>
                        <div className='item-cart-image col-sm'>
                          <img src={Rectangle1} alt="Casque" />
                        </div>
                        <div className='item-cart-name col-sm-5 col-12'>
                          <h1>Casque</h1>
                        </div>
                        <div className='item-cart-price col-sm-3 col-12 float-end'>
                          <h2>9.99 € </h2>
                        </div>
                      </div>
                    </div>
                  )
                case '5':
                  return (
                    <div className='item-cart-container'>
                      <div className='item-cart-delete'>
                        <IconButton className='test-cart col-1' aria-label="delete" size="large" onClick={() => removeItem(item.id)}>
                          <Delete color='error' fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div className='row align-items-center'>
                        <div className='item-cart-image col-sm'>
                          <img src={Rectangle5} alt="Éclairage" />
                        </div>
                        <div className='item-cart-name col-sm-5 col-12'>
                          <h1>Éclairage</h1>
                        </div>
                        <div className='item-cart-price col-sm-3 col-12 float-end'>
                          <h2>4.99 € </h2>
                        </div>
                      </div>
                    </div>
                  )
                case '6':
                  return (
                    <div className='item-cart-container'>
                      <div className='item-cart-delete'>
                        <IconButton className='test-cart col-1' aria-label="delete" size="large" onClick={() => removeItem(item.id)}>
                          <Delete color='error' fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div className='row align-items-center'>
                        <div className='item-cart-image col-sm'>
                          <img src={Rectangle} alt="Éclairage" />
                        </div>
                        <div className='item-cart-name col-sm-5 col-12'>
                          <h1>Batterie</h1>
                        </div>
                        <div className='item-cart-price col-sm-3 col-12 float-end'>
                          <h2>79.99 € </h2>
                        </div>
                      </div>
                    </div>
                  )
              }
            }
            )}
          </div>
          <div class="price-cart">
            <h2 className='float-start total'>TOTAL</h2>
            <h2 className='float-end price'>{total.toFixed(2)} € </h2>
          </div>
          <div class="bottom-cart">

            <button type="button" className="btn btn-cart-pay float-end" onClick={handleCloseOk}> Payer</button>

          </div>
        </Box>
      </Modal>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
          <a className="navbar-brand" href="index.html"><img id="logoimg" src={logo} alt="Chaliar logo" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span><i className="fas fa-bars"></i></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item"> <a className="nav-link" href="https://chaliar.com/index.html#commentMarche">Concept</a> </li>
              <li className="nav-item"> <a className="nav-link" href="https://chaliar.com/index.html#nosVehocules">Avantages</a> </li>
              <li className="nav-item"> <a className="nav-link" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contactForm">Contact</a> </li>
              <li className="nav-item"> <a className="nav-link" href="#">Shop</a> </li>
              <li className="nav-item"> <a className="nav-link blueactive" href="https://chaliar.com/devenirchaliar.html">Devenir chaliar</a> </li>
              <li className="nav-item img-item"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg/2560px-Flag_of_Great_Britain_%281707%E2%80%931800%29.svg.png" width="35" height="35" class="rounded-circle" />                            </li>
            </ul>
          </div>
        </nav>
        {/* <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
          <a className="navbar-brand" href="index.html"><img id="logoimg" src={logo} alt="Chaliar logo" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span><i className="fas fa-bars" /></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item"> <a className="nav-link" href="#">Concept</a> </li>
              <li className="nav-item"> <a className="nav-link" href="#">Avantages</a> </li>
              <li className="nav-item"> <a className="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#contactForm">Contact</a> </li>
              <li className="nav-item"> <a className="nav-link" href="#">Shop</a> </li>
              <li className="nav-item"> <a className="nav-link blueactive" href="#">Devenir chaliar</a> </li>
            </ul>
          </div>
        </nav> */}
      </header>
      <section className="shoplanding-section chali-com">
        <div className="chali-com d-flex align-items-center shopland-one">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-md-6 col-12" id ="testIDmerde">
                <div className="shopland-img text-center">
                  <img src={Oval} alt="image" />
                </div>
              </div>
              <div className="col col-md-6 col-12" id="testIDmerde2">
                <div className="shopland-content">
                  <h4>Sécurité</h4>
                  <h2 className="mb-5">Soyez toujours bien équipé, pour livrer en toute sécurité !</h2>
                  <p>Vous avez prévu de livrer demain ? Avant de vous lancer, partez bien équipé.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="productlist-section chali-com">
        <div className="clearfix" style={{ paddingBottom: '35px', paddingTop: '35px' }}>
          <button type="button" className="btn btn-light panier float-end" onClick={handleClickOpen}> <FontAwesomeIcon style={{ paddingRight: '10px' }} icon={faShoppingCart} /> Mon Panier<Badge pill style={{ marginLeft: '10px' }} bg="danger">{cart.length}</Badge> </button>
        </div>
        <div className="container ">
          <div className="row">
            <div className="col col-lg-4 col-md-6 col-12 mb-4">
              <div className="product-box">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#shopDetail">
                  <div className="product-img">
                    <img src={Rectangle4} alt="Tee-shirt" />
                    <div className="overlay">
                      <button onClick={addTeeShirtToCart} type="button" className="btn btn-primary " style={{ position: 'relative', top: '50%', backgroundColor: '#042C5C', outlineColor: '#042C5C' }}>Ajouter
                        au Panier - 19.99€</button>
                    </div>
                  </div>
                  <div className="product-title">
                    <h4>Tee-shirt</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-12 mb-4">
              <div className="product-box">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#shopDetail">
                  <div className="product-img">
                    <img src={Rectangle3} alt="Pull" />
                    <div className="overlay">
                      <button onClick={addPullToCart} type="button" className="btn btn-primary " style={{ position: 'relative', top: '50%', backgroundColor: '#042C5C', outlineColor: '#042C5C' }}>Ajouter
                        au Panier - 39.99€</button>
                    </div>
                  </div>
                  <div className="product-title">
                    <h4>Pull</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-12 mb-4">
              <div className="product-box">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#shopDetail">
                  <div className="product-img">
                    <img src={Rectangle2} alt="Sac isotherme" />
                    <div className="overlay"><button onClick={addSacToCart} type="button" className="btn btn-primary " style={{ position: 'relative', top: '50%', backgroundColor: '#042C5C', outlineColor: '#042C5C' }}>Ajouter
                      au Panier - 29.99€</button></div>
                  </div>
                  <div className="product-title">
                    <h4>Sac Isotherme</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-12 mb-4">
              <div className="product-box">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#shopDetail">
                  <div className="product-img">
                    <img src={Rectangle1} alt="Casque de protection" />
                    <div className="overlay"><button onClick={addCasqueToCart} type="button" className="btn btn-primary " style={{ position: 'relative', top: '50%', backgroundColor: '#042C5C', outlineColor: '#042C5C' }}>Ajouter
                      au Panier - 9.99€</button></div>
                  </div>
                  <div className="product-title">
                    <h4>Casque de Protection</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-12 mb-4">
              <div className="product-box">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#shopDetail">
                  <div className="product-img">
                    <img src={Rectangle5} alt="Éclairage avant LE" />
                    <div className="overlay"><button onClick={addEclairageToCart} type="button" className="btn btn-primary " style={{ position: 'relative', top: '50%', backgroundColor: '#042C5C', outlineColor: '#042C5C' }}>Ajouter
                      au Panier - 4.99€</button></div>
                  </div>
                  <div className="product-title">
                    <h4>Éclairage avant LED</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-12 mb-4">
              <div className="product-box">
                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#shopDetail">
                  <div className="product-img">
                    <img src={Rectangle} alt="Batterie autonome4" />
                    <div className="overlay"><button onClick={addBatterieToCart} type="button" className="btn btn-primary " style={{ position: 'relative', top: '50%', backgroundColor: '#042C5C', outlineColor: '#042C5C' }}>Ajouter
                      au Panier - 79.99€</button></div>
                  </div>
                  <div className="product-title">
                    <h4>Batterie Autonome</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-12 chali-com">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div className="box-panel d-flex align-items-center">
                <div className="box-body box-left">
                  <img src={Rectanglexz} alt="image" />
                </div>
                <div className="box-body box-right text-center">
                  <h2>Roulez avec Chaliar </h2>
                  <p>Vous souhaitez devenir coursier indépendant ? Rejoignez nous aujourd'hui pour compléter vos revenus !
                  </p>
                  <div className="blue-anch justify-content-center mt-5"> <a href className="lightblue">Devenir chaliar</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="chali-com">
        <div className="footer-one chali-com">
          <div className="container" data-aos="fade-up" data-aos-duration={1000}>
            <div className="row justify-content-center">
              <div className="col col-lg-8 col-12">
                <div className="f-one-content text-center mb-5">
                  <h2>Commandez votre chaliar en quelques clics</h2>
                  <p>Nous proposons un suivi géolocalisé en temps-réel de tous les colis transportés, du point de retrait à la livraison, couplé à un système de notifications avancées par SMS</p>
                </div>
                <div className="storelink text-center">
                  <h4>Téléchargez gratuitement</h4>
                  <a href><img src={footer_appstore} alt="App Store" /></a>
                  <a href><img src={footer_playstore} alt="Playstore" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="fobot-img">
            <img src={footer_bottom} alt="footer img" />
          </div>
        </div>
        <div className="footer-two chali-com">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-xl-4 col-md-3 col-12">
                <div className="footerlogo">
                  <a href="index.html"><img src={logobleu} alt="logo" /></a>
                </div>
              </div>
              <div className="col col-xl-8 col-md-9 col-12">
                <div className="row">
                  <div className="col col-md-4 col-12">
                    <div className="footerlink-div">
                      <h5>Menu</h5>
                      <ul>
                        <li><a href>Accueil</a></li>
                        <li><a href>Concept</a></li>
                        <li><a href>Avantages</a></li>
                        <li><a href>Contact</a></li>
                      </ul>
                    </div>
                    <div className="footerlink-div mt-4">
                      <h5>Nous écrire</h5>
                      <ul>
                        <li><a href="mailto:contact@chaliar.fr">contact@chaliar.fr</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-md-4 col-12">
                    <div className="footerlink-div">
                      <h5>Devenir Chaliar</h5>
                      <ul>
                        <li><a href>Inscription chaliar</a></li>
                        <li><a href>Presse</a></li>
                        <li><a href>Conditions générales </a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-md-4 col-12">
                    <div className="footerlink-div">
                      <h5>Téléchargez</h5>
                      <ul>
                        <li><a href>IOS</a></li>
                        <li><a href>ANDROID</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-three chali-com">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-md-4 col-12 tcenter">
                <p>Copyright 2021 Chaliar. All rights reserved.</p>
              </div>
              <div className="col col-md-4 col-12">
                <div className="des-dev-anch text-center">
                  <a href>Design &amp; developpement Likeweb Agency</a>
                </div>
              </div>
              <div className="col col-md-4 col-12">
                <div className="socail-anch">
                  <a href><i className="fab fa-facebook-f" /></a>
                  <a href><i className="fab fa-instagram-square" /></a>
                  <a href><i className="fab fa-google" /></a>
                  <a href><i className="fab fa-twitter" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <header>
        <Navbar expand="lg" className="custom-btn py-1">
          <Container>
            <Navbar.Brand href="#home">
              <img
                alt=""
                src={logo}
                className="d-inline-block align-top"
              />{' '}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home">Concept</Nav.Link>
                <Nav.Link href="#link">Avantages</Nav.Link>
                <Nav.Link href="#link">Contact</Nav.Link>
                <Nav.Link href="#link">Devenir chaliar</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header> */}
    </div>
  );
}

export default App;
